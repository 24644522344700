@import "../../../ColorScheme.css";
@import "../../../BlueScheme.css";

.ic-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    border: none;
}

.icon-circle {
    width: 120px; /* Reduced size */
    height: 120px; /* Reduced size */
    border-radius: 50%;
    border: 5px solid transparent;
    background: linear-gradient(var(--light-color), var(--light-color)) padding-box,
    linear-gradient(var(--light-blue), var(--dark-blue)) border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px; /* Adjusted font size */
    color: var(--dark-blue);
}

.i-icon {
    color: var(--light-blue) !important;
    width: 50%;
    height: 50%;
    margin: auto;
}

.i-card-body {
    color: var(--dark-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
    width: 100%;
}

.i-card-title {
    color: var(--dark-blue);
    font-weight: bold;
    font-size: 1.2em;
}

@media (max-width: 768px) {
    .icon-circle {
        width: 60px;
        height: 60px;
        background: none;
    }

    .i-icon {
        width: 80%;
        height: 80%;
    }
}