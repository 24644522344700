:root {
    --primary-color-dark: #952F49;
    --primary-color-darker: #671c30;
    --primary-color-light: #96b6a8;
    --secondary-color-dark: #145DA0;
    --secondary-color-light: #50ACE4;
    --dark-color: #000000;
    --dark-color-medium: #292929;
    --light-color: #FFFFFF;
    --light-color-medium: #ABABAB;
    --transparent-50: rgba(0, 0, 0, 0.188)
}
