@import "../../../ColorScheme.css";
@import "../../../BlueScheme.css";

.ic-card-w {
    border: 500px;
    border-radius: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.icon-circle-w {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}


.i-icon-w {
    color: var(--light-color) !important;
    width: 50%;
    margin: auto;
    height: 50%;
    font-size: 2em;
}

.i-card-body-w {
    color: var(--light-color);
    align-items: center;
    justify-content: center;
    padding: 25px 20px;
    text-align: center;
    font-size: 1em;
    height: 100%;
    width: 100%;
}

.i-card-title-w {
    color: var(--light-color);
    font-weight: bold;
    font-size: 1.2em;
}

.i-card-text-w {
    font-size: 1em;
}

@media (max-width: 768px) {
    .icon-circle-w {
        background: none;
    }

    .i-card-body-w {
        font-size: 1em;
    }

    .icon-circle-w {
        width: 60px;
        height: 60px;
    }

    .i-icon-w {
        height: 100%;
    }
}
