@import "../../ColorScheme.css";

.exams-body-text-center {
    background-color: var(--primary-color-dark);
    color: var(--light-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Corrected align-content */
    padding: 100px 15px;
    margin-bottom: 40px;
}

.exams-body-text-center.container-fluid h2 {
    margin-bottom: 40px;
}

.main-exams {
    color: var(--dark-blue);
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

.other-exams-bx {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin: 70px 15px 15px 15px;
    color: var(--dark-blue);
}

.other-exams-bx h2 {
    font-weight: bold;
}

.other-exams {
    max-width: 1262px;
    padding: 35px;
    border-radius: 50px;
    border: 2px solid var(--dark-blue);
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: flex-start; /* changed to 'flex-start' */
    align-items: center;
    flex-wrap: wrap;
}

/* Merged .exams-body-text-center and its nested h2, p into one rule */
.exams-body-text-center h2 {
    text-align: center;
    margin: 0 auto;
    font-weight: bold;
}

.exams-body-text-center p {
    margin: 0 auto;
    text-align: justify;
    max-width: 700px;
}

.mx-w {
    max-width: 1262px;
    margin: auto;
}

.c-wine {
    background-color: var(--primary-color-dark);
}

.insurance-body-l {
    background-color: var(--primary-color-dark);
    display: flex;
    flex-direction: row; /* Content right, image left */
    align-items: center;
    justify-content: space-around;
    padding: 15px;
    max-width: 1200px;
    margin: 0 auto;
}

.insurance-body-content-l {
    flex: 1;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    max-width: 500px;
}

.insurance-body-content-l-img-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    flex: 0 1 50%; /* Ensures wrapper is responsive */
    margin: 0;
}

.insurance-body-content-l-img {
    background-color: #61dafb;
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.w-text {
    color: var(--light-color);
}

.insurance-body-end {
    display: flex;
    flex-direction: row;
    align-items: flex-start; /* Corrected align-content to align-items */
    justify-content: flex-start;
    text-align: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    height: 500px;
}

.insurance-body-end-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 40%;
    box-sizing: border-box;
    color: var(--primary-color-dark);
}

.insurance-body-end-img-wrapper {
    width: 60%;
    height: 100%;
    display: flex;
}

.insurance-body-end-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.insurance-body-end-content, .insurance-body-end-img-wrapper {
    margin: 0;
    padding: 20px;
}

.insurance-body-end-title {
    color: var(--primary-color-darker);
    font-weight: bold;
    font-size: 2em;
    margin: 15px 0;
}

.insurance-body-end-btn {
    background-color: var(--primary-color-darker);
    color: var(--light-color);
    font-weight: bold;
    border: none;
    border-radius: 50px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 2em;
    transition: background-color 0.3s ease;
}

.insurance-body-end-btn:hover {
    background-color: var(--primary-color-dark);
    color: var(--light-color);
}

@media (max-width: 950px) {
    .insurance-body-end-content, .insurance-body-end-img-wrapper {
        width: 100%;
        height: auto;
    }

    .insurance-body-l {
        flex-direction: column-reverse;
        max-height: none;
    }

    .insurance-body-end {
        height: 100%;
        flex-direction: column-reverse;
    }
}