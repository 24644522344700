/* NavBar.css */

/* Import color scheme */
@import "../../ColorScheme.css";

.navbar-toggler {
    border: 0;
}

.navbar-toggler:active {
    border: 0;
}

.top {
    width: 100%;
    height: 125px;
    position: fixed; /* Ensure the navbar is fixed at the top */
    top: 0;
    left: 0;
    z-index: 1000; /* High z-index to overlap other elements */
}

/* Wrapper Styles */
.wrapper {
    transition: background-color 0.3s ease;
    width: 100%;
}

.nav-under {
    position: relative;
}

.nav-under.scrolled::after {
    background-color: var(--primary-color-light);
}

.nav-under.transparent::after {
    background-color: var(--light-color);
}
.nav-under.red-bg::after {
    background-color: var(--primary-color-dark);
}

.nav-under::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    border-radius: 15px;
    left: 0;
    bottom: 0;
    transform: scale(0, 1);
    transition: transform 0.3s ease;
}

.nav-under:hover::after {
    transform: scale(1, 1);
}

.dropdown-item:hover {
    background-color: transparent;
}

.wrapper.transparent {
    background-color: transparent;
}
.wrapper.red-bg {
    background-color: var(--primary-color-dark);
}

.wrapper.scrolled {
    background-color: var(--light-color); /* Background color when scrolled */
}

/* Content Styles */
.content {
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: 10px 20px;
    margin: auto;
}

/* Logo Styles */
.logo img {
    height: 30px;
}

/* Menu Styles */
.menu {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: end;
    width: 100%;
}

/* Submenu Styles */
.submenu {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-around;
    text-align: left;
    align-items: start;
}

/* Navbar Item Styles */
.nav-item {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    align-items: end;
    width: auto;
    font-weight: bold;
}

/* Dropdown Menu Styles */
.dropdown-menu {
    border: 2px solid;
    border-radius: 4px; /* Optional: add rounded corners */
    padding: 0; /* Remove default padding */
    margin: 0; /* Remove default margin */
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.dropdown-menu.transparent {
    background-color: rgba(255, 255, 255, 0); /* Transparent background */
    border-color: rgba(255, 255, 255, 0); /* Transparent border */
}
.dropdown-menu.red-bg {
    background-color: var(--primary-color-dark);
}

.dropdown-menu.scrolled {
    background-color: var(--light-color); /* Background color when scrolled */
    border-color: var(--light-color); /* Border color when scrolled */
}

.dropdown-item {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    align-items: end;
    width: auto;
    font-weight: bold;
}

.dropdown-item.transparent {
    color: var(--light-color); /* Text color when transparent */
}
.dropdown-item.red-bg {
    background-color: var(--primary-color-dark);
}

.dropdown-item.scrolled {
    color: var(--dark-color); /* Text color when scrolled */
}

/* Element Styles */
.element.transparent {
    color: var(--light-color); /* Icon color when transparent */
}
.element.red-bg {
    background-color: var(--primary-color-dark);
}

.element.scrolled {
    color: var(--dark-color); /* Icon color when scrolled */
}

.element.transparent .nav-link {
    color: var(--light-color); /* Link color when transparent */
}
.element.red-bg .nav-link{
    background-color: var(--primary-color-dark);
}

.element.scrolled .nav-link {
    color: var(--dark-color); /* Link color when scrolled */
}

/* Icon Styles */
.my-icon {
    font-size: 30px;
}

.nav-bar {
    background-color: transparent;
}
