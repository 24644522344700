@import "../../ColorScheme.css";

.carousel-container {
    width: 100%;
    background-color: var(--light-color);
}

.carousel-item {
    height: 70vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--light-color);
}

.carousel-item img {
    height: 70vh;
    width: 100%;
    object-fit: contain;
}

@media (max-width: 800px) {
    .carousel-item {
        height: auto;
    }

    .carousel-item img {
        height: auto;
        width: 100%;
    }
}