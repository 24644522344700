@import '../../ColorScheme.css';

.dpz {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dpz-btn {
    margin-top: 10px;
    background-color: var(--primary-color-dark);
    color: var(--light-color);
    font-weight: bold;
}

.dpz-btn:hover {
    background-color: var(--primary-color-darker);
    color: var(--light-color);
}