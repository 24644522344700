@import "../../ColorScheme.css";

.banner-body {
    background-color: var(--primary-color-light);
    color: var(--primary-color-darker);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    position: relative;
}

.solid-box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
}

.text-banner {
    position: relative;
    z-index: 2;
}

.banner-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(20%, -50%);
    font-size: 15em;
    z-index: 1;
    color: white;
}

.banner-title {
    font-size: 4.9em;
    font-weight: bold;
    line-height: 1.5;
}

.banner-text {
    font-size: 1.5em;
    font-weight: bold;
}

@media (max-width: 672px) {
    .banner-title {
        font-size: 3.5em;
        font-weight: bold;
        line-height: 1.5;
    }
    .banner-icon {
        display: none;
    }
}

@media (max-width: 350px) {
    .banner-title {
        font-size: 2.8em;
        font-weight: bold;
        line-height: 1.5;
    }
    .banner-icon {
        display: none;
    }
}