html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden;
    word-wrap: break-word;
}

header.fixed-top {
    width: 100%;
    z-index: 1000;
}

main {
    overflow-wrap: break-word;
    word-break: break-word;
}

.whatsapp-element {
    display: none;
    transition: all 0.3s ease;
}

.whatsapp-element.scrolled {
    display: block;
}

footer {
    background-color: var(--light-color);
    overflow-wrap: break-word;
    word-break: break-word;
}
