.contact-banner {
    background-color: var(--primary-color-light);
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.pdd {
    padding: 0 40px;
}


.contact-banner-title {
    padding-top: 20px;
    font-size: 5em;
    margin: 0 0 0 30px;
    color: var(--primary-color-darker);
    font-weight: bold;
    text-shadow: 0 0 15px var(--primary-color-darker); /* Adjust the values for glow effect */
}

.contact-banner-text {
    width: 400px;
    margin: 30px;
}

.contact-banner-btn {
    font-weight: bold;
    background-color: white;
    font-size: 1.5em;
    color: var(--dark-color-medium);
    margin: 0 20%;
    border-radius: 20px;
    box-shadow: 0px 4px 6px rgba(0, 18, 107, 0.89); /* Applying box shadow */
}

.contact-banner-btn:hover {
    font-weight: bold;
    background-color: var(--light-color-medium);
    color: var(--dark-color);
    border-radius: 20px;
}

.contact-banner-content {
    position: absolute;
    z-index: 4;
    padding: 20px;
    color: var(--primary-color-darker);
    font-weight: bold;
    width: 100%; /* Ocupa 50% da largura */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: linear-gradient(to right, var(--primary-color-light), var(--primary-color-light), var(--primary-color-light), transparent);
}

.contact-banner-img-container {
    position: relative;
    width: 100%; /* Ocupa 100% da largura */
    height: 100%; /* Ocupa 100% da altura do contêiner */
    display: flex;
    align-items: center;
    justify-content: flex-end; /* Alinha a imagem à direita */
    overflow: hidden; /* Impede que a imagem ultrapasse o contêiner */
}


.contact-banner-img {
    width: 70%;
    height: 100%; /* Ocupa 100% da altura do contêiner *//* Mantém a proporção da imagem */
    object-fit: cover;
    object-position: left; /* Mostra a parte esquerda da imagem */
    position: relative;
    z-index: 1;
    opacity: 0.6;
}
.contact-banner-ico-container {
    margin-top: 500px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: relative;
    z-index: 4;
    opacity: 1;
    rotate: 270deg;
}

.f2 {
    border: none;
    max-width: 1262px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-radius: 50px;
}

.transparse {
    display: block;
    position: relative;
    width: 100%;
}

.form-select {
    border: 2px solid var(--primary-color-light);
    background-color: var(--primary-color-light);
    margin: 2px;
    color: var(--dark-color-medium);
    font-weight: bold;
}

.form-cont {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.dp-m{
    border: 2px solid var(--primary-color-darker);
    color: var(--light-color);
    padding: 5px;
    background-color: var(--primary-color-dark);
    text-align: justify;
}
.fbr {
    border-radius: 25px;
    padding: 15px;
    margin: auto 20px;
    width: 100%;
    border: 2px solid var(--primary-color-light);
    color: var(--dark-color-medium);
    background-color: var(--primary-color-light);
    font-weight: bold;
    height: auto;
    .form-group {
        width: 90%;
        border: 2px solid var(--primary-color-light);
    }
}

@media (max-width: 800px) {
    .form-cont {
        flex-direction: column-reverse;
    }

    .fbr {
        margin: 15px;
    }
}

@media (max-width: 820px) {

    .contact-banner-title {
        font-size: 3em;
        margin: 0;
        padding-top: 15px;
    }

    .contact-banner-text {
        font-size: 1em;
        color: var(--dark-color-medium);
        margin: 15px;
        font-weight: bold;
        max-width: 100%;
    }

    .contact-banner-img-container {
        display: none;
    }

    .contact-banner {
        background: linear-gradient(to right, var(--light-color), var(--primary-color-light));
        justify-content: center;
        align-items: center;
    }

    .contact-banner-content {
        background: none;
        width: 50%;
        left: 15%;
    }
}

@media (max-width: 773px) {
    .contact-banner-content {
        background: none;
        width: 100vw;
        left: 0;
        transform: none;
        padding: 20px;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .contact-banner-btn {
        font-size: 1em;
    }
}