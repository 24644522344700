body { /* Fixed selector */
    width: 100%;
}

.home-banner {
    color: var(--light-color);
    background-color: var(--primary-color-dark);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    position: relative;
}

.home-banner-img {
    position: absolute;
    height: 100vh;
    width: 100%;
    object-fit: cover;
    z-index: 1;
    opacity: 0.4;
}

.home-banner-btn {
    position: fixed;
    bottom: 32px;
    right: 32px;
    height: 60px;
    background-color: #25D366;
    border: 2px solid white;
    z-index: 3;
    color: var(--light-color);
    font-weight: bold;
    border-radius: 50px;
    overflow: hidden;
    max-width: 200px; /* Adjust this value based on your button's initial width */
    transition: max-width 0.3s, opacity 0.3s, visibility 0.3s; /* Added transition */
}

.home-banner-btn.scrolled {
    max-width: 0;
    height: 50px;
    border-radius: 50px;
    opacity: 0;
    visibility: hidden;
}

.home-row-2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.hr-coll {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.home-coll {
    width: calc(30% - 15px); /* Definindo a largura máxima de cada coll */
    margin: 0 7.5px; /* Espaçamento entre os colls */
    height: 100%; /* Define a altura máxima do home-coll */
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
}

.home-coll-big {
    width: calc(70% - 15px); /* Fixed consistent spacing */
    margin: 7.5px;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
}

.home-body-title {
    position: absolute;
    width: 100%;
    text-align: left;
    font-weight: bold;
}

.tt1 {
    z-index: 0;
    bottom: 50%;
    color: var(--dark-color-medium);
}

.tt2 {
    z-index: 2;
    bottom: 30%;
    left: 10%;
    color: var(--primary-color-dark);
}

.home-body-img {
    z-index: 1;
    margin-left: auto;
    left: 70%;
    max-height: 90%;
    max-width: 90%;
    object-fit: cover;
}

/* Large screens */
@media (min-width: 1200px) {
    .home-body-title {
        font-size: 3em;
    }
}

/* Medium screens */
@media (max-width: 1200px) {
    .home-body-title {
        font-size: 2.5em;
    }
}

/* Tablets */
@media (max-width: 768px) {
    .home-row-2 {
        flex-direction: column;
        align-items: center;
        max-height: 100%;
        max-width: 80%;
        margin: 0 auto;
    }

    .home-coll,
    .home-coll-big {
        width: 100%;
        margin: 15px 0; /* Vertical spacing */
    }

    .home-body-title {
        font-size: 2.2em;
    }
}

/* Mobile phones */
@media (max-width: 576px) {
    .home-body-title {
        font-size: 1.8em;
    }

    .banner-title {
        font-size: 1.3em;
    }
}

/* Small phones */
@media (max-width: 360px) {
    .home-body-title {
        font-size: 1.5em;
    }
}

.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: #000;
    border-radius: 8px;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.home-btn {
    margin: 0 auto;
}

.home-coll a {
    text-align: center;
}