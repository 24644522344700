@import "../../ColorScheme.css";

.ff {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.form-work {max-width: 1262px;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border: 2px solid var(--primary-color-dark);
    border-radius: 50px;
    h1{
        color: var(--primary-color-dark);
        font-weight: bold;
    }
}

input {
    text-decoration: none;
}
.mm{
    margin: 10px;
}

.form-group {
    width: 50%;
    border-bottom: 2px solid var(--primary-color-light);
    color: var(--primary-color-light);
}

.form-group-drop {
    align-items: center;
    border: 2px dashed var(--primary-color-light);
    padding: 15px;
    color: var(--primary-color-light);
    font-weight: bold;
    border-radius: 25px;
}

@media (max-width: 762px) {
    .form-work{
        width: 100%;
        margin-left: 15px;
        margin-right: 15px;
    }
    .form-group {
        width: 90%;
    }
}
