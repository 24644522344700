.i-card-set {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    padding: 20px 0;
}

.card-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}

.card-wrapper .card {
    flex: 1;
}