@import "../../ColorScheme.css";

.head {
    padding: 15px 0;
    text-align: center;
    background-color: var(--secondary-color-dark);
}

.head a {
    text-decoration: none;
    font-weight: bold;
    color: var(--light-color);
}