@import "../../ColorScheme.css";

/* Footer Container */
.footer {
    width: 100%;
    padding-top: 20px;
}

/* Footer Content */
.footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: auto;
}

/* Block Map and Contact Styles */
.block-map, .block-contact, .block-contact-blue {
    border: 3px solid black;
    border-radius: 25px;
    margin: 12px;
    box-sizing: border-box;
    flex: 1 1 calc(100% - 24px); /* Flex 100% for mobile */
    max-width: calc(100% - 24px);
    height: 100%;
}

/* Block Map Specific Styles */
.block-map {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
}

.map {
    width: 100%;
    position: relative;
    padding-bottom: 30%; /* 16:9 Aspect Ratio */
    height: 100%;
}

.map iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.map-text {
    padding: 20px;
    color: var(--dark-color);
    flex: 1;
}

.map-text-content {
    display: block;
    width: 100%;
    height: 100%;
}

.map-text-content p {
    width: 100%;
    clear: both;
    font-size: 1em;
}

.map-text h3 {
    font-weight: bold;
}

/* Block Contact and Block Contact Blue */
.block-contact, .block-contact-blue {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.block-contact {
    color: var(--dark-color);
    background-color: var(--primary-color-light);
    height: auto;
}

.block-contact-blue {
    color: var(--light-color);
    background-color: var(--dark-blue);
}

/* Heading and Paragraph Styles for Both Blocks */
.block-contact h3, .block-contact-blue h3 {
    font-weight: bold;
}

.block-contact p, .block-contact-blue p {
    margin-bottom: 10px;
}

/* Footer Links */
.footer-links {
    margin: 20px 50px;
}

.footer-links a {
    margin-right: 15px;
    color: var(--dark-color);
    text-decoration: none;
}

.footer-links a:hover {
    text-decoration: underline;
}

/* Social Icons */
.social {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
    padding-right: 20px;
}

.block-contact .social .icon {
    color: var(--primary-color-darker);
}

.block-contact-blue .social .icon {
    color: var(--light-blue);
}

.social .icon:hover {
    color: var(--dark-color);
}

/* Contact Text */
.contact-text {
    margin: auto;
    display: flex;
    flex-direction: row;
    word-break: break-word;
    justify-content: space-around;
}

.contact-text h5 {
    font-weight: bold;
}

.contact-text p {
    font-size: 1em;
}

/* Media Queries for Responsive Design */
@media (min-width: 1200px) {
    .footer-content {
        height: auto;
    }

    .block-map {
        height: auto;
        flex: 0 1 calc(70% - 24px);
        max-width: calc(70% - 24px);
        flex-direction: row;
    }

    .contact-text {
        flex-direction: column;
        align-items: center;
        height: 100%;
    }

    .map iframe {
        margin: auto;
    }

    .social {
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        height: 50%;
        width: 100%;
    }

    .map-text {
        padding: 20px;
        color: var(--dark-color);
        flex: 1;
        margin: auto;
    }

    .block-contact, .block-contact-blue {
        flex: 0 1 calc(30% - 24px);
        max-width: calc(30% - 24px);
        justify-content: space-around;
    }
}

@media (max-width: 600px) {
    .contact-text {
        flex-direction: column;
        align-items: center;
        height: auto;
    }

    .contact-text-content {
        max-width: 100%;
    }

    .map {
        padding-bottom: 100%;
    }

    .social {
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        height: auto; /* Adjusted to auto */
        width: 100%;
    }

    .block-contact, .block-contact-blue {
        height: auto;
        justify-content: space-around;
        flex-direction: column;
    }
}