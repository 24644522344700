@import "../../ColorScheme.css";
@import "../../BlueScheme.css";

.doctor-banner {
    background-color: var(--dark-blue);
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.pdd {
    padding: 0 40px 40px 40px;
}


.doctor-banner-title {
    padding-top: 15px;
    font-size: 5em;
    margin: 0 0 0 30px;
    color: var(--snow);
    font-weight: bold;
}

.doctor-banner-text {
    width: 400px;
    margin: 30px;
}

.doctor-body {
    margin: 40px auto;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-around;
    width: 100%;
    max-width: 700px;
}

.doctor-body-title {
    font-weight: bold;
    font-size: 3em;
    margin: 5px;
    text-align: center;
}

.dtt2 {
    color: var(--dark-blue);
}

.dtt1 {
    color: var(--light-blue);
}

.doctor-body-text {
    padding: 15px;
    text-align: center;
    font-weight: bold;
}

.doctor-body-content {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
}

.doctor-banner-btn {
    font-weight: bold;
    background-color: white;
    font-size: 1.5em;
    color: var(--dark-color-medium);
    margin: 0 20%;
    border-radius: 20px;
    box-shadow: 0 4px 6px rgba(0, 18, 107, 0.89); /* Applying box shadow */
}

.doctor-banner-btn:hover {
    font-weight: bold;
    background-color: var(--light-color-medium);
    color: var(--dark-color);
    border-radius: 20px;
}

.doctor-banner-content {
    position: absolute;
    z-index: 4;
    padding: 20px;
    color: white;
    width: 50%; /* Ocupa 50% da largura */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: linear-gradient(to right, var(--dark-blue), var(--dark-blue), var(--dark-blue), transparent);
}

.doctor-banner-img-container {
    position: relative;
    width: 100%; /* Ocupa 100% da largura */
    height: 100%; /* Ocupa 100% da altura do contêiner */
    display: flex;
    align-items: center;
    justify-content: flex-end; /* Alinha a imagem à direita */
    overflow: hidden; /* Impede que a imagem ultrapasse o contêiner */
}


.doctor-banner-img {
    width: 70%;
    height: 100%; /* Ocupa 100% da altura do contêiner *//* Mantém a proporção da imagem */
    object-fit: cover;
    object-position: left; /* Mostra a parte esquerda da imagem */
    position: relative;
    z-index: 1;
    opacity: 0.6;
}

@media (max-width: 820px) {

    .doctor-banner-title {
        font-size: 5em;
        margin: 0;
        padding-top: 15px;
    }

    .doctor-banner-text {
        font-size: 1em;
        margin: 15px;
        font-weight: bold;
        max-width: 100%;
    }

    .doctor-banner-img-container {
        display: none;
    }

    .doctor-banner {
        background: linear-gradient(to right, var(--dark-blue), var(--light-blue));
        justify-content: center;
        align-items: center;
    }

    .doctor-banner-content {
        background: none;
        width: 50%;
        left: 15%;
    }
}

@media (max-width: 773px) {
    .doctor-banner-content {
        background: none;
        width: 100vw;
        left: 0;
        transform: none;
        padding: 20px;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .doctor-banner-btn {
        font-size: 1em;
    }
}

.med-content {
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--dark-blue);

    h2 {
        font-weight: bold;
        text-align: center;
    }

    .form-select {
        background-color: transparent;
        color: black;
    }
}

.header {
    font-weight: bold;
    text-align: center;
    color: var(--dark-blue);
    padding: 40px;
}

.bg-l-blue {
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 20px;

    .form-group {
        border-bottom: 5px solid transparent;
    }

    button {
        background-color: var(--dark-blue);
    }

    button:hover {
        background-color: var(--dark-blue-50);
    }

    .form-select {
        font-weight: normal;
        border: 1px solid #ced4da;
        background-color: var(--light-color);
    }

    border-radius: 25px;

    .width-50 {
        width: calc(100% - 24px); /* Subtraindo 12px de cada lado */
        margin: 0 12px;
    }
}

.width-50 {
    width: 50%;
    margin: 0 auto;
}

@media (max-width: 600px) {
    .doctor-banner-title {
        font-size: 4.5em;
        margin-bottom: 30px;
    }

    .doctor-banner-text {
        margin-bottom: 30px;
    }

    .width-50 {
        width: 100%;
    }

    .header {
        padding: 10px;
    }
}