@import "../../../ColorScheme.css";
@import "../../../BlueScheme.css";

.img-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin: 20px auto;
    width: calc(100% - 30px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.img-card-body {
    color: var(--dark-color);
    background-color: var(--light-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    text-align: justify;
    font-size: 1em;
    height: 100%;
    border: 5px solid var(--light-color);
    margin: 0;
    overflow: hidden;
    width: 100%;
    border-radius: 8px;
}

.img-card-title {
    color: var(--dark-blue);
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
}

.img-card-text {
    text-align: center;
    font-size: 1em;
    line-height: 1.5;
}

.card-img-top {
    width: 100%;
    max-width: 250px;
    height: 250px;
    object-fit: contain;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
}