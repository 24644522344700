@import "../../ColorScheme.css";

.c-gray {
    background-color: var(--primary-color-light);
}

.about-body-text-center {
    background-color: var(--primary-color-light);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 100px 15px;
}

.about-body-text-center h2 {
    font-size: 2em;
}

.about-body-text-center {
    h2 {
        text-align: center;
        margin: 20px auto;
        font-weight: bold;
        color: var(--primary-color-dark);
    }

    p {
        margin: 0 auto;
        text-align: justify;
        max-width: 700px;
        color: var(--primary-color-dark);
    }
}

.about-btn {
    background-color: var(--primary-color-dark);
    color: var(--light-color);
    font-size: 1.5em;
    font-weight: bold;
    border-radius: 50px;
}

.about-btn:hover {
    background-color: var(--primary-color-darker);
    color: var(--light-color);
    font-size: 1.5em;
    font-weight: bold;
    border-radius: 50px;
}

.about-body-l {
    display: flex;
    flex-direction: row; /* Content on the right, image on the left */
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 15px 50px;
}

.about-body-content-l {
    flex: 1;
    margin: 0 auto; /* Adjust margin as needed */
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    max-width: 500px;
}

.content-l-title {
    font-weight: bold;
    font-size: 2em;
    margin: 30px 0;
}

.content-l-text {
    text-align: start;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.about-body-content-l-img-wrapper {
    display: flex;
    align-items: center;
    flex: 0 1 40%; /* Allow the wrapper to be responsive */
}

.about-body-content-l-img {
    background-color: #61dafb;
    width: 100%;
    height: auto;
    object-fit: contain;
}

.cards-about {
    background-color: var(--primary-color-dark);
}

@media (max-width: 800px) {
    .about-body-l {
        flex-direction: column;
    }

    .about-btn {
        font-size: 1em;
    }

    .about-btn:hover {
        font-size: 1em;
    }
}