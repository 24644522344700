@import "../../../ColorScheme.css";

.c-blue {
    background-color: var(--dark-blue);
    width: 100%;
}

a {
    text-decoration: none;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Fundo escuro transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Sombra suave */
    animation: fadeIn 0.3s ease-in-out; /* Animação de fade-in */
    max-width: 500px;
    width: 100%;
}

.modal-header {
    border-bottom: 1px solid #dee2e6;
    padding-bottom: 10px;
}

.modal-title {
    font-size: 24px;
    color: var(--dark-blue);
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
}

.modal-footer .btn {
    background-color: var(--dark-blue);
    color: #fff;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.modal-footer .btn:hover {
    background-color: #0056b3;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


.card-img-top {
    border-radius: 100%;
    width: 200px;
    height: 200px;
    object-fit: cover;
}

.icon-carousel-cards {
    width: 100%;
    padding-bottom: 30px;
}

@media (max-width: 768px) {
    .card-img-top {
        width: 100px;
        height: 100px;
    }
}