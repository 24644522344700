@import "../../../ColorScheme.css";

.card {
    width: 100%;
    border: none;
    border-radius: 0;
}

.card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 25px 20px;
    color: var(--light-color);
    text-align: center;

    height: 25vh;

    border: 5px solid var(--light-color);
    border-radius: 0;
    margin: 0;
    width: 100%;
}

.card-title {
    font-weight: bold;
    font-size: 1.2em;
}

@media (max-width: 768px) {
    .card-body {
        padding: 20px 15px;
        height: auto;
    }
}
