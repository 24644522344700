.card-set {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
}

.card-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    flex: 1 1 300px;
}

.card-wrapper .card {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.even-card .card-body {
    background-color: var(--primary-color-dark);
    color: var(--light-color);
}

.odd-card .card-body {
    background-color: var(--primary-color-light);
    color: var(--primary-color-dark);
}